import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/user/user.service';
import { DatePipe } from '@angular/common';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import * as CryptoJS from 'crypto-js';
import * as alertifyjs from 'alertifyjs';
import Swal from 'sweetalert2';




@Component({
    selector: 'akeela-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [DatePipe]

})
export class LoginComponent implements OnInit {

    templates: any = {};
    object: any = {};
    form:any;
    formValores:any;

    submit_disabled = false;
    lang:any;
    errors:any = [];
    ip:any;

    result:any;
    searchPerson:any;
    person:any;
    section:any= 1;
    screenHeight =screen.height;
    type_identity:any = [];
    code_identity:any = [];
    person_response:any ;
    loadingSubmit:any ;

    submit_disabled_contact:any;
    validate_contact:any;
  
    activeValidateTlf = false;
    dataValidateTlf:any;
    loading:any;
     step=1;
     secretKey = "juan";
     contacts:any = [];
     env = environment;
     re_pass:any;
     re_pass_resp:any;

    correoMinLength:any;
    correoMaxLength:any;
    passwordMinLength:any;
    passwordMaxLength:any;

    validateObli = true;

    constructor(
        private formBuilder: FormBuilder,
        private _user:UserService,
        private datePipe: DatePipe,
        private router: Router,
        public _auth: AuthService,
        private redirecFormService:RedirecFormService,
        private offcanvasService: NgbOffcanvas,

    ) {
      
     }

  
     ngOnInit(): void {

      this.correoMinLength = 6;
        this.correoMaxLength = 30;
        this.passwordMinLength = 6;
        this.passwordMaxLength = 12;

        this._user.tlfValidates$.subscribe((response) => {

            // if(response[this.validate_contact]){
             if(response[this.dataValidateTlf]){
                this.loading = true;
                this.re_pass_resp = true;

                this.setFormBase();

              //   this.submit(this.dataValidateTlf);
       
             }
        
           
          });

    
        this.form = this.formBuilder.group({
          type_identity: ['CI',[Validators.required]],
          code_identity: ['V',[Validators.required]],
          identity: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(8)]],
          contact: ['']
      });
    
      this.getLists();
    
      }
    
      getLists = async () =>  {
        const [ type_identity,code_identity] = await Promise.all([
          await this._user.getPersonTypeIdentity(),
          await this._user.getPersonCodeIdentity()
      ]);
    
        this.type_identity = type_identity.resp;
        this.code_identity = code_identity.resp;
      }
    


    submit = async (contact:any=this.dataValidateTlf) => {
      Swal.fire({
        title: 'Importante',
        html: `
          
          <img src="../assets/public/images/STORY-CREDITOTAL.jpg" alt="Imagen descriptiva" style="max-width: 100%; height: auto;">
        `,
        customClass: {
          icon: 'no-border'
        },
        showCloseButton: true,
        focusConfirm: false,
        confirmButtonText: 'Entendido',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Cancelar'
      });
  //     if ( this.formValores.valid) {
  //     this.errors = [];

  //     this.offcanvasService.dismiss('Cross click');

  //    this.loadingSubmit = true;

  //        this.submit_disabled = true;
       
  //        const body = {
  //         username: this.form.get('identity').value,
  //         password:this.formValores.get('password').value,
  //         role:"CLIENT_ROLE",
  //         dmfjusr: (this.re_pass_resp? "A58Ddsda54565as45443SDFSD5423423dfsf5445": "4SDF654654sdfssdA58Ddsda54563SDFSD5423423dfsf5445fsdf45")
          
  //     }
  //     try {
        
  //       let resp = await this._auth.login(body);

  //       this._auth.setAuth(resp);
  //       this.router.navigate([environment.redirectRoute], {});

  //         this.submit_disabled = false;

  //         this.loadingSubmit = false;
  //     } catch (error:any) {

  //         if(error?.error?.errors){
  //             this.errors = error?.error?.errors;
  //         }

  //         if(error?.error?.msg){
  //             this.errors =   [...this.errors,{msg:error?.error?.msg} ];
  //         }

  //         console.error(error);
  //         this.submit_disabled = false;

  //     }

  //     this.loadingSubmit = false;
  // }
  }





  
next = async (n:any) => {
  Swal.fire({
    title: 'Importante',
    html: `
      
      <img src="../assets/public/images/STORY-CREDITOTAL.jpg" alt="Imagen descriptiva" style="max-width: 100%; height: auto;">
    `,
    customClass: {
      icon: 'no-border'
    },
    showCloseButton: true,
    focusConfirm: false,
    confirmButtonText: 'Entendido',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    cancelButtonText: 'Cancelar'
  });
    //   if ( this.form.valid) {
    //     this.submit_disabled = true;
    //     const data = this.form.getRawValue();

    // try {

    //  let resp = await this._auth.getContact(data);
                       
    //  let result:any =  this.decrypt(resp?.res);

    //   let respuesta =  JSON.parse(result);

    //   let contacts_temp = respuesta.contacts;

    //   let contacts :any= [];

    //   contacts_temp?.map((contact:any)=>{
    //    let temp = {type_contact: contact?.type_contact?.code,contact:contact?.contact};

    //    contacts = [...contacts,temp];
    //           return temp;
    //     });

    //     this.contacts  = contacts;


    //  if(respuesta?.password){

    //   this.setFormBase();

    // }else{
    //    this.step = 2;

    // }



    //   this.submit_disabled = false;



    // } catch (error:any) {

    //   alertifyjs.error(error?.error?.msg);

    //     this.submit_disabled = false;


    // }


  


    };

    decrypt(textToDecrypt : string){
      return CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8);
    }


getCodeBuroIdentity(code:any){
    let resp;
  switch (code) {
    case "V":
      resp = 1;
      break;
      case "CP":
        resp = 2;
        break;
        case "E":
          resp = 3;
          break;
          case "P":
            resp = 4;
            break;
  
  }
  
  return resp;
  }
  
  
   jsDateToEpoch = (d:any) => {
  
    // d = javascript date obj
    // returns epoch timestamp
    return (d.getTime())
  }
  
  
    showValidateTel = (content: TemplateRef<any>,contact:any) =>{


     this.activeValidateTlf = true;
     this.dataValidateTlf = contact;
  
      this._user.setShowValidateTel(contact);
      this.offcanvasService.open(content, { position: 'bottom', keyboard: false });


    }
  
  
  
    showValidateTel2 = () =>{
      this.loading = true;
      let contact =  this.form.getRawValue()?.contact;
     this.dataValidateTlf = contact;
     this.submit();
  
    }
  
  
    closeValidateTlf = (e:any) => {
  
      this.activeValidateTlf = false;  
      this.offcanvasService.dismiss('Cross click');

  
  }
  
  setValidateTlf = (e:any) => {
      let datas = this._user.tlfValidates$.getValue();
  
      datas[e] = true;
  
      this._user.setTlfValidates(datas);
  
  }
    
    sendNoteClient = async (msg:any,phone:any) => {
      
      try {
        const data =  {
            phone,
            msg,
            "client": "KREDIT"
          }
      
              let result = await this._user.sendsmsGeneral(data);
      
              if (!(result?.error)) {
      
      
              }
      
      } catch (error) {
        console.error(error);
        
        
      }
    
      
  
  
  
   }
  


   getHiddenPhone(response:any){
    let text_count = response?.length;
    let d1 = text_count-4;
    let d2 = text_count;
    let text_hidden='';

    for (let index = 0; index < d1; index++) {
       text_hidden = `${text_hidden}*`;
      
    }

    return `${text_hidden}${response.slice(d1,d2)}`;


  }


  validateContact = (content: TemplateRef<any>,contact:any) =>{

       this.showValidateTel(content,contact?.contact);

  }


  setFormBase(){
    this.step = 4;
    this.offcanvasService.dismiss('Cross click');

    const data = this.form.getRawValue();

    this.formValores = this.formBuilder.group({
        
        username: [`${data.code_identity}${data.identity}`, [Validators.required]],
        password: ['', [Validators.required, Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]]
   
    });

  }

  reset(){
this.step= 1;
this.re_pass_resp = false;

this.re_pass = null;
this.form = this.formBuilder.group({
  type_identity: ['CI',[Validators.required]],
  code_identity: ['V',[Validators.required]],
  identity: ['',[Validators.required,Validators.minLength(6),Validators.maxLength(8)]],
  contact: ['']
});


  }


}
