<div class="container">
    <div class="mt-md-3 p-3">
        <div class="row">
            <div class="col-12 col-md-7 offset-md-3 p-0">
                <h5 class="mb-1 text-secondary mb-4 text-center">
                    <i class="fas fa-user-times"></i>
                    Cerrar cuenta
                </h5>


                <h4 class="pt-2 pb-2 text-start">
                    ¿Por qué quieres cerrar tu cuenta?
                </h4>

                <h6 class="mb-1 text-secondary mb-4 text-start break-word">
                    Lamentamos que te quieras ir. Quisiéramos saber la razón por la cual te vas para mejorar nuestra aplicación.
                </h6>

                <form [formGroup]="form">  
                        <div class="form-group">

                                    <div class="form-floating">
                                        <select class="form-select" formControlName="reason">
      <option value="">Seleccione</option>
      <option *ngFor="let item of reasonsArray" [value]="item.name">
        {{ item.name }}
      </option>
    </select>

                                        <label for="reason">Razón</label>
                                    </div>

                                    <!-- <div class="form-control-feedback help-block" >
                                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div> -->
                                </div>
                </form>


                <div class="col-12 border-bottom mt-4 mb-0"></div>

                <form [formGroup]="form">  
                        <div class="form-floating mb-3 mt-4" >
            <input type="text" 
                   class="form-control" 
                   id="name" 
                   formControlName="name" 
                   placeholder="name">
            <label for="name" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Nombre</span>
            </label>

            <div *ngIf="form.get('name')?.invalid && form.get('name')?.touched" class="text-danger">
                *Campo requerido
            </div>
        </div>

        <div class="form-floating mb-3 mt-3" >
            <input type="text" 
                   class="form-control" 
                   id="lastName" 
                   formControlName="lastName" 
                   placeholder="lastName">
            <label for="lastName" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Apellido</span>
            </label>

            <div *ngIf="form.get('lastName')?.invalid && form.get('lastName')?.touched" class="text-danger">
                *Campo requerido
            </div>
        </div>

        <div class="form-floating mb-3 mt-3" >
            <input type="text" 
                   class="form-control" 
                   id="email" 
                   formControlName="email" 
                   placeholder="email">
            <label for="email" class="label font-weight-normal" style="word-wrap: break-word;">
                <span>Correo electrónico</span>
            </label>

            <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="text-danger">
                *Campo requerido
            </div>
        </div>

        <!-- <div class="form-floating">
                                        <select class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option >hola</option>
                    </select>

                                        <label >Tipo de Documento</label>
                                    </div> -->

                                    <div class="form-group  col-12">
                                        <div class="row">


                                    <div class="form-group  col-3">

                                    <div class="form-floating">
                                        <select  class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                        <option *ngFor="let item of code_identity" [value]="item?.code">{{item?.name}}</option>
                                 </select>

                                        <label for="code_identity">Tipo</label>
                                    </div>

                                    <!-- <div class="form-control-feedback help-block" >
                                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div> -->
                                </div>
                                <div class="form-group  col-9">

                                    <div class="form-floating ">
                                        <input type="text"  class="form-control text-capitalize" id="identity" formControlName="identity">
                                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Documento </span>
                 </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                                        <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                            Mínimo 7 caracteres
                                        </div>

                                        <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                            Máximo 8 caracteres
                                        </div>

                                    </div>
                                </div>
                                </div>
                                </div>
                </form>

                    <div class="mt-4 p-2 rounded shadow-sm alert-card">
                            <!-- [routerLink]="['/console/online']" ARRIBA -->
                            <div>
                                <span class="button-style btn d-flex text-start break-word">
                                    Este procedimiento no es automático, nuestro equipo revisará la solicitud para asegurarse de que el usuario cumple con los requisitos de pago completo y cierre de créditos activos. El plazo de verifiación es de aprox. 15 días.
                                <br>
                                <br>
                                Una vez verificados los requisitos, se procederá a la eliminación de los datos del usuario de nuestros sistemas. El usuario recibirá una confirmación una vez que la eliminación haya sido completada.
                                </span>
                            </div>
                        </div>

                <div class="col-12 mt-4 mb-2">
                    <div class="text-end">
                                        <button type="submit" class="btn btn-block btn-primary" (click)="saveCustomerDataDeletion()" [disabled]="form.invalid">
                                                                <span >Cerrar cuenta</span>
                                                                
                                                              </button>
                                    </div>
                                    </div>
            </div>
        </div>
    </div>
</div>