import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';

import { BehaviorSubject} from 'rxjs';
import { environment } from '../../../environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';


@Injectable({
  providedIn: 'root'
})
export class SocketioService {
  private usersActives$: BehaviorSubject<any> = new BehaviorSubject([]);
  public usersMsg$: BehaviorSubject<any> = new BehaviorSubject([]);
  private resetstadistic$: BehaviorSubject<any> = new BehaviorSubject(null);
  private resetDataClientCode$: BehaviorSubject<any> = new BehaviorSubject(null);
  private msgDataClient$: BehaviorSubject<any> = new BehaviorSubject(null);

  

  env:any = environment;
  socket : any;
  private connet: any;

  constructor(

  ) {

/*
    const id =  this._auth.sessionUser;
    
    */
  }


  get usersActives(){

    return  this.usersActives$;

  }


  

  get msgDataClient(){

    return  this.msgDataClient$;

  }
  get resetstadistic(){

    return  this.resetstadistic$;

  }


  get resetDataClientCode(){

    return  this.resetDataClientCode$;

  }


  
  setupSocketConnection(token:any) {
    if(this.env?.socketBase?.SOCKET_ENDPOINT ){

     this.socket = io(this.env.socketBase.SOCKET_ENDPOINT,{
      extraHeaders: {
        "x-token": token
      }
    });

      this.setSocketOn(token);
    }

  }

  setSocketOn = (token:any) =>{

    this.socket.on('connect', () => {
      console.log('Conectado');
      this.connet = true;

    });

    this.socket.on('disconnect', () => {
      console.log('Desconectado del servidor');
      this.usersActives$.next([]);

      this.connet = false;

    });


    this.socket.on('reset-status-after-bio', (code: any) => {

      console.log('code',code);
      
      debugger

   });





    this.socket.on('personal-msg', (payload: any) => {
      
      if(payload){
        this.msgDataClient$.next(payload);

      }



  
    });  


    this.socket.on('reset-stadistic', (resetstadistic: any) => {
      
           this.resetstadistic$.next(resetstadistic);

      });


      this.socket.on('reset-Data-client-code', (resetDataClientCode: any) => {

          this.resetDataClientCode$.next(resetDataClientCode);

      });


    this.socket.on('escuchar-mensaje-privado', (payload: any) => {

       console.log("para mi ",payload);

        let msgs =  this.usersMsg$.getValue();

        msgs = [payload,...msgs]

        this.setUsersMsg(payload);

     });

     

     

     


  }

  setUsersMsg(payload:any){
    let msgs =  this.usersMsg$.getValue();

    msgs = [...msgs,payload]
    this.usersMsg$.next(msgs);
  }

  get statusConnet(){
     return this.connet;
  }

  get socketConnet(){
    return this.socket;
 }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();

    }
  }

  mensajePrivado(uid:any,mensaje:any = "") {

      this.socket.emit("mensaje-privado",{uid,mensaje});

  }


  setResetstadistic(data:any) {
    this.socket.emit("reset-stadistic-post",{resp:true});

 }

}
