import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-delete-account-apk',
  templateUrl: './delete-account-apk.component.html',
  styleUrls: ['./delete-account-apk.component.css'],
})
export class DeleteAccountApkComponent implements OnInit {
  form: any;

  type_identity: any[] = [];
  code_identity: any[] = [];

  reasonsArray: any[] = [];

  constructor(private formBuilder: FormBuilder, private _user: UserService) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      // type_identity: ['CI', [Validators.required]],
      code_identity: ['V', [Validators.required]],
      identity: [
        '',
        [Validators.required, Validators.minLength(6), Validators.maxLength(8)],
      ],
      reason: [''],
      email: ['', Validators.required],
    });

    console.log(this.form)

    this.getLists();
    console.log(this.code_identity)
    this.mappedReasons();
    console.log(this.reasonsArray)
  }

  reasons: { [key: string]: string } = {
    1: 'Ya no uso el servicio',
    2: 'Preocupaciones sobre privacidad o seguridad',
    3: 'Encontré una mejor alternativa',
    4: 'Mala experiencia con el servicio',
    5: 'Mala atención al cliente',
    6: 'Otra razón',
  };

  getLists = async () => {
    debugger;
    const [type_identity, code_identity] = await Promise.all([
      await this._user.getPersonTypeIdentity(),
      await this._user.getPersonCodeIdentity(),
    ]);

    this.type_identity = type_identity.resp;
    this.code_identity = code_identity.resp;
    console.log(this.code_identity)
  };

  mappedReasons() {
    this.reasonsArray = Object.entries(this.reasons).map(([key, value]) => ({
      value: key,
      name: value,
    }));
  }

  async saveCustomerDataDeletion() {
    console.log(this.form.value);
    if (this.form.valid) {
      const body = {
        name: this.form.get('name')?.value,
        lastName: this.form.get('lastName')?.value,
        code_identity: this.form.get('code_identity')?.value,
        identity: this.form.get('identity')?.value,
        reason: this.form.get('reason')?.value,
        email: this.form.get('email')?.value,
      };
      console.log(body);

      const result = await Swal.fire({
      title: '¿Deseas eliminar tu cuenta?',
      text: '¡No podrás revertir esto!',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar!',
      allowOutsideClick: () => !Swal.isLoading(),
      })
      
      if (result.isConfirmed) {
        try {
        await this._user.deleteAccountCustomer(body);
        this.form.reset();
        Swal.fire({
          icon: 'success',
          title: 'Eliminado!',
          text: 'Tu cuenta ha sido eliminada.',
        });
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Ocurrió un error al eliminar tu cuenta.',
        });
      }

      }
    }
  }
}
